var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetLogStyled', [_c('AssetInfoStyled', [_c('table', {
    staticClass: "column"
  }, [_c('tr', {
    staticClass: "row"
  }, [_c('td', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$tc('lastUpdate', 1)))]), _c('td', {
    staticClass: "value"
  }, [_c('TimeAgo', {
    attrs: {
      "time": _vm.assetLastUpdate
    }
  })], 1)])])]), _c('ErrorLog', {
    attrs: {
      "isWidget": false,
      "assetID": _vm.assetID,
      "assetLastUpdate": _vm.assetLastUpdate
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }